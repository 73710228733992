import * as React from 'react';

export type CarouselProps = {
  children: React.ReactNode[] | React.ReactNode;
  heading: React.ReactNode;
  headingUrl?: string;
  viewAllUrl?: string;
  className?: string;
  shouldShowViewAllCard?: boolean;
  status: 'completed' | 'loading' | '';
  variant?: 'large' | '';
};

export type ButtonsStatus = {
  leftArrow: 'disable' | 'enabled';
  rightArrow: 'disable' | 'enabled';
  viewAllButton: 'disable' | 'enabled';
  disableScroll: boolean;
};

export const DEFAULT_BUTTON_STATUS: ButtonsStatus = {
  leftArrow: 'disable',
  rightArrow: 'enabled',
  viewAllButton: 'disable',
  disableScroll: false,
};
