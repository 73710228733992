import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { getElementScrollRightDistance } from '../../utils/getElementScrollRightDistance';
import { isMobile } from '../../utils/userAgent';
import styles from './Carousel.module.scss';
import { CarouselHeader } from './CarouselHeader';
import { CarouselNavControl } from './CarouselNavControl';
import { ButtonsStatus, CarouselProps, DEFAULT_BUTTON_STATUS } from './CarouselProps.type';
import { CarouselSwipeContent } from './CarouselSwipeContent';
import { getCarouselChildWidth } from './getCarouselChildWidth';
const SCROLL_BY_ITEMS_AMOUNT = isMobile ? 3 : 4;
const LARGE_CAROUSEL_SCROLL_BY_ITEMS_AMOUNT = 1;
export const Carousel = memo(({
  children,
  className,
  heading,
  headingUrl,
  viewAllUrl,
  shouldShowViewAllCard,
  status,
  variant
}: CarouselProps) => {
  const [buttonsStatus, setButtonsStatus] = useState<ButtonsStatus>(DEFAULT_BUTTON_STATUS);
  const carouselSwipeContentRef = useRef<HTMLDivElement>(null);
  const onClickPrevious = useCallback(() => {
    carouselSwipeContentRef.current?.scrollBy({
      left: -Math.min(getCarouselChildWidth({
        carouselSwipeContentRef
      }) * (variant === 'large' ? LARGE_CAROUSEL_SCROLL_BY_ITEMS_AMOUNT : SCROLL_BY_ITEMS_AMOUNT), carouselSwipeContentRef.current.scrollLeft),
      behavior: 'smooth'
    });
  }, [variant]);
  const onClickNext = useCallback(() => {
    carouselSwipeContentRef.current?.scrollBy({
      left: Math.min(getCarouselChildWidth({
        carouselSwipeContentRef
      }) * (variant === 'large' ? LARGE_CAROUSEL_SCROLL_BY_ITEMS_AMOUNT : SCROLL_BY_ITEMS_AMOUNT), getElementScrollRightDistance(carouselSwipeContentRef.current)),
      behavior: 'smooth'
    });
  }, [variant]);
  const updateNavigation = useCallback((containerTarget: HTMLDivElement | null) => {
    if (containerTarget) {
      if (containerTarget.scrollLeft + containerTarget.clientWidth >= containerTarget.scrollWidth - 1) {
        setButtonsStatus(status => ({
          ...status,
          leftArrow: 'enabled',
          rightArrow: 'disable',
          disableScroll: false
        }));
      } else if (containerTarget.scrollLeft === 0) {
        setButtonsStatus(status => ({
          ...status,
          leftArrow: 'disable',
          rightArrow: 'enabled',
          disableScroll: false
        }));
      } else if (containerTarget.scrollLeft > 0) {
        setButtonsStatus(status => ({
          ...status,
          leftArrow: 'enabled',
          rightArrow: 'enabled',
          disableScroll: false
        }));
      }
    }
  }, []);
  const updateCarouselLayout = useCallback(() => {
    if (!carouselSwipeContentRef.current || status === 'loading') {
      return;
    }
    const scrollWidth = carouselSwipeContentRef.current.scrollWidth;
    const width = carouselSwipeContentRef.current.getBoundingClientRect().width;
    if (!scrollWidth && !width) {
      return;
    }
    if (scrollWidth <= Math.round(width)) {
      setButtonsStatus(state => ({
        ...state,
        leftArrow: 'disable',
        rightArrow: 'disable',
        disableScroll: true
      }));
    } else {
      updateNavigation(carouselSwipeContentRef.current);
    }
  }, [status, updateNavigation]);
  useEffect(() => {
    updateCarouselLayout();
  }, [updateCarouselLayout]);
  useEffect(() => {
    window.addEventListener('resize', updateCarouselLayout);
    return () => window.removeEventListener('resize', updateCarouselLayout);
  }, [updateCarouselLayout]);
  return <section className={className}>
        {variant !== 'large' && <CarouselHeader heading={heading} viewAllUrl={viewAllUrl} headingUrl={headingUrl} onClickPrevious={onClickPrevious} onClickNext={onClickNext} buttonsStatus={buttonsStatus} />}

        <div className={styles.swipeContent}>
          <CarouselSwipeContent status={status} variant={variant} shouldShowViewAllCard={shouldShowViewAllCard} disabledScroll={buttonsStatus.disableScroll} ref={carouselSwipeContentRef} viewAllUrl={viewAllUrl} updateNavigation={updateNavigation}>
            {children}
          </CarouselSwipeContent>

          {variant !== 'large' && !buttonsStatus.disableScroll && <button aria-label="Scroll to the right" className={`${styles.carouselFade} ${buttonsStatus.rightArrow === 'disable' ? styles.hideCarouselFade : ''}`} onClick={onClickNext} />}

          {variant === 'large' && <CarouselNavControl onClickPrevious={onClickPrevious} onClickNext={onClickNext} buttonsStatus={buttonsStatus} />}
        </div>
      </section>;
});