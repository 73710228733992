import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Heading from '../Text/Heading';
import styles from './CarouselHeader.module.scss';
import { ButtonsStatus, CarouselProps } from './CarouselProps.type';
type Props = {
  onClickPrevious: () => void;
  onClickNext: () => void;
  buttonsStatus: ButtonsStatus;
} & Pick<CarouselProps, 'viewAllUrl' | 'heading' | 'headingUrl'>;
export const CarouselHeader = ({
  headingUrl,
  onClickPrevious,
  buttonsStatus,
  onClickNext,
  heading,
  viewAllUrl
}: Props) => {
  const {
    t
  } = useTranslation();
  return <div className={styles.root}>
      <Heading level={3} className={styles.heading}>
        {headingUrl ? <Link href={headingUrl}>{heading}</Link> : heading}
      </Heading>

      <div className={styles.buttonPanel}>
        {viewAllUrl && <Link href={viewAllUrl} className={styles.viewAllButton}>
            {t('btnViewAll')}
          </Link>}

        <button disabled={buttonsStatus.leftArrow === 'disable'} aria-label={'scroll left'} className={styles.navButton} onClick={onClickPrevious}>
          <img src="/icons/chevron.svg" className={styles.leftArrow} alt="arrow left" />
        </button>

        <button disabled={buttonsStatus.rightArrow === 'disable'} aria-label={'scroll right'} className={styles.navButton} onClick={onClickNext}>
          <img src="/icons/chevron.svg" className={styles.rightArrow} alt="arrow right" />
        </button>
      </div>
    </div>;
};