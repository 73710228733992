import styles from './Heading.module.scss';
interface HeadingProps {
  /**
   * Heading level. Eg H1, H2, H3, H4 ...
   */
  level: 1 | 2 | 3 | 4 | 5;
  className?: string;
  /**
   * Heading text
   */
  children: string | React.ReactNode;
}
const Heading = ({
  level,
  children,
  className
}: HeadingProps) => {
  switch (level) {
    case 1:
      return <h1 className={`${styles.h1} ${className || ''}`}>{children}</h1>;
    case 2:
      return <h2 className={`${styles.h2} ${className || ''}`}>{children}</h2>;
    case 3:
      return <h3 className={`${styles.h3} ${className || ''}`}>{children}</h3>;
    case 4:
      return <h4 className={`${styles.h4} ${className || ''}`}>{children}</h4>;
    case 5:
      return <h5 className={`${styles.h5} ${className || ''}`}>{children}</h5>;
    default:
      return null;
  }
};
export default Heading;