import React from 'react';

export function getCarouselChildWidth({
  carouselSwipeContentRef,
}: {
  carouselSwipeContentRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  let carouselChildWidth = 0;

  if (carouselSwipeContentRef.current) {
    const firstElement = carouselSwipeContentRef.current.firstChild;

    if (firstElement) {
      carouselChildWidth = (firstElement as HTMLAnchorElement)?.getBoundingClientRect().width ?? 0;
    }
  }

  return carouselChildWidth;
}
