import React from 'react';
import styles from './TallGameCardElement.module.scss';
type Props = {
  children: React.ReactNode;
  color?: string;
};
const LIGHTEN_COLOR_PERCENTAGE_TO_DECIMAL = 0.3;
function lightenColor(color: string) {
  let r: string | number = Number.parseInt(color.substring(1, 3), 16);
  let g: string | number = Number.parseInt(color.substring(3, 5), 16);
  let b: string | number = Number.parseInt(color.substring(5, 7), 16);
  r = Math.round(r + (255 - r) * LIGHTEN_COLOR_PERCENTAGE_TO_DECIMAL);
  g = Math.round(g + (255 - g) * LIGHTEN_COLOR_PERCENTAGE_TO_DECIMAL);
  b = Math.round(b + (255 - b) * LIGHTEN_COLOR_PERCENTAGE_TO_DECIMAL);
  r = r.toString(16).padStart(2, '0');
  g = g.toString(16).padStart(2, '0');
  b = b.toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
}
export const GameTilePlaceholder = ({
  children,
  color
}: Props) => {
  return <div className={styles.root} style={color ? {
    background: `linear-gradient(90deg, ${color} 0%, ${lightenColor(color)} 50%, ${color} 100%)`,
    backgroundSize: '200% 100%'
  } : {}}>
      {children}
    </div>;
};