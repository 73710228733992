import Link from 'next/link';
import * as React from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RightSidebarVariant } from '../../redux/slices/browserPreferenceSlice';
import { CarouselProps } from './CarouselProps.type';
import styles from './CarouselSwipeContent.module.scss';
import ViewAllCard from './ViewAllCard';
type Props = Pick<CarouselProps, 'variant' | 'viewAllUrl' | 'children' | 'status' | 'shouldShowViewAllCard'> & {
  disabledScroll: boolean;
  updateNavigation: (containerTarget: HTMLDivElement | null) => void;
};
export const CarouselSwipeContent = React.forwardRef<HTMLDivElement, Props>(({
  variant,
  children,
  disabledScroll,
  status,
  viewAllUrl,
  shouldShowViewAllCard,
  updateNavigation
}, ref) => {
  const rightSidebarVariant = useSelector((state: AppState) => state.browserPreference.rightSidebarVariant);
  const onScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => updateNavigation((e.target as HTMLDivElement)), [updateNavigation]);
  return <div className={`${styles.root} ${variant === 'large' ? styles.largeItem : ''} ${rightSidebarVariant === RightSidebarVariant.NONE ? '' : styles.applyContentSize} ${disabledScroll ? styles.disabledScroll : ''}`} ref={ref} onScroll={onScroll}>
        {children}

        {shouldShowViewAllCard && viewAllUrl && !disabledScroll && status !== 'loading' && <Link href={viewAllUrl}>
            <ViewAllCard />
          </Link>}
      </div>;
});