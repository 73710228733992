import styles from './CarouselNavControl.module.scss';
import { ButtonsStatus } from './CarouselProps.type';
type Props = {
  onClickPrevious: () => void;
  onClickNext: () => void;
  buttonsStatus: ButtonsStatus;
};
export function CarouselNavControl({
  onClickPrevious,
  onClickNext,
  buttonsStatus
}: Props) {
  return <nav className={styles.root}>
      <button className={`${styles.arrow} ${styles.left} ${buttonsStatus.leftArrow === 'disable' ? styles.hide : ''}`} onClick={onClickPrevious}>
        <img src="/icons/chevron.svg" alt="arrow" />
      </button>

      <button className={`${styles.arrow} ${styles.right} ${buttonsStatus.rightArrow === 'disable' ? styles.hide : ''}`} onClick={onClickNext}>
        <img src="/icons/chevron.svg" alt="arrow" />
      </button>
    </nav>;
}