import { useTranslation } from 'next-i18next';
import { Flex } from '../Flex';
import { GameTilePlaceholder } from '../Cards/TallGameCard/GameTilePlaceholder';
import styles from './ViewAllCard.module.scss';
const ViewAllCard = () => {
  const {
    t
  } = useTranslation();
  return <GameTilePlaceholder>
      <div className={styles.viewAllCardElement}>
        <div className={styles.viewAllTop} />
        <div className={styles.viewAllBottom} />

        <div className={styles.viewAllContents}>
          <Flex justify="space-between">
            <img src="/icons/logo-star.svg" alt="star" />
            <p className={styles.viewAllText}>{t('btnViewAll')}</p>
          </Flex>
          <div className={styles.viewAllCenter}>
            <img src="/icons/shuffle-logo-tall.svg" alt="logo" />
          </div>
          <Flex justify="flex-end">
            <img src="/icons/logo-star.svg" alt="star" />
          </Flex>
        </div>
      </div>
    </GameTilePlaceholder>;
};
export default ViewAllCard;